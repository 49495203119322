<template>
	<div v-if="horizontal != 'true'" :style="{'background':`url(${footerBg})`,'background-size': '100% 100%'}"
		class="footer-box">
		<div class="title textCenter">
			<p class="Handwriting">
				<img class="imgH" :src="logoImg" alt="" />
				{{name}}
			</p>
		</div>
	</div>
</template>
  
<script>
import { mapState } from 'vuex'


export default {
	name: 'Footer',
	components: {
	},
	data() {
		return {
			horizontal: null,
			logoImg: require(`@/assets/img/logo-icon.png`),
			// areaName:'南关厢历史文化街区',
			footerBg: require(`@/assets/img/${process.env.VUE_APP_IMG_IMG_FOLDER}/footer-bg.png`)
		}
	},

	computed: {
		...mapState(["name"]),
	},
	methods: {


	},
	mounted() {
		this.horizontal = String(localStorage.getItem('screen'))
	}
}
</script>
  
<style lang="less" scoped>
.footer-box {
	text-align: center;
	height: 100%;
	width: 100%;
	padding-top: 15%;
	font-size: 60px;
	box-sizing: border-box;

	img {
		vertical-align: middle;
		height: 120px;
		margin-right: 10px;
	}



}
</style>
  