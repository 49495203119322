<template>
    <div>

        <HeaderVue></HeaderVue>
        <!-- 横屏 -->
        <div v-if="horizontal == 'true'" class="default-box-h">
            <div class="default-l">
                <router-view></router-view>
            </div>
            <div class="default-r">

                <NavVue></NavVue>
            </div>
        </div>
        <!-- 竖屏 -->
        <div v-else>
            <div class="default-box-v">
                <router-view></router-view>
            </div>
            <NavVue></NavVue>
        </div>
    </div>




</template>


<script>
import { mapMutations, mapState } from 'vuex'
import NavVue from '@/components/Nav.vue';
import HeaderVue from '@/components/Header.vue';

export default {
    name: 'Default',
    components: {
        HeaderVue,
        NavVue
    },
    data() {
        return {
            horizontal: null

        }
    },
    created() {

    },
    watch: {

    },
    computed: {

    },
    methods: {


    },
    mounted() {

        this.horizontal = String(localStorage.getItem('screen'))


    }
}
</script>

<style lang="less" scoped>
.default-box-h {
    position: relative;

    .default-l {
        padding: 10px
    }

    .default-r {
        width: 280px;
        position: fixed;
        right: 0;

    }
}

.default-box-v {}
</style>