//1. 导入 Vue 和 VueRouter 的包
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Default from '../views/Default.vue'

//2. 调用Vue.use()函数，把VueRouter安装为Vue的插件
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/guide'
    },
    {
        path: '/', // localhost:8080/  即根路径
        name: 'Home',
        component: Home,

        children: [
            {
                path: '/guide',
                name: 'Guide',
                meta: {
                    title: '导航导览',
                    isShow: true,
                    showFooter: true
                },
                component: () => import('../views/Guide/Index.vue'),
            }, {
                path: '/guide/:lid',
                name: 'Guide',
                meta: {
                    title: '导航导览',
                    isShow: true,
                },
                component: () => import('../views/Guide/Index.vue'),
            },
            {
                path: '/consultation',
                name: 'Consultation',
                meta: {
                    title: '街区资讯',
                    layout: 'defaultLayout',
                    isShow: true,
                    showFooter: true
                },
                component: () => import('../views/Consultation/Index.vue'),
            },

            {
                path: '/recommend',
                name: 'Recommend',
                meta: {
                    title: '热门推荐',
                    isShow: true,
                    showFooter: true
                },
                component: () => import('../views/Recommend/Index.vue'),
            },
            {
                path: '/recommend/:id',
                props: true, //开启 属性解构 路由参数的功能
                name: 'Recommend',
                meta: {
                    title: '热门推荐列表',
                    isShow: false,
                    isShowDetails: true
                },
                component: () => import('../views/Recommend/List.vue'),
            },
            {
                path: '/convenience',
                name: 'Convenience',
                meta: {
                    title: '便民服务',
                    isShow: true,
                    showFooter: true
                },
                component: () => import('../views/Convenience/Index.vue'),
            },

            {
                path: '/scenicspot',
                name: 'Scenicspot',
                meta: {
                    title: '景区介绍',
                    isShowDetails: true
                },
                component: () => import('../views/Scenicspot/Index.vue'),
            },
            {
                path: '/details/:id',
                props: true, //开启 属性解构 路由参数的功能
                name: 'Details',
                meta: {
                    title: '点位详情页',
                    isShow: false,
                    isShowDetails: true,
                    isDetailsPage: true
                },
                component: () => import('../views/Details.vue'),
            },

        ]
    },

]

//3. 创建路由的实例对象
const router = new VueRouter({
    mode: 'hash',
    base: '',
    routes,
})

//4. 向外共享路由的实例对象
export default router

