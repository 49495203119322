import Cookies from './cookies'

import axios from "axios"
const serviceApi = axios.create({
  baseURL: 'https://guides.toureyes.cn/api/v1',
  timeout: 3000
})

serviceApi.interceptors.request.use(
  config => {
    config.headers = {
      'Access-Control-Allow-Origin': '*',
      'SG-Authorization': process.env.VUE_APP_SG_AUTHORIZATION,
      'Content-Type': 'application/json'
    }
    return config
  }, err => {
    return Promise.reject(err)
  }
)

let toureyesTracking = window.toureyesTracking || window.ToureyesTracking || {}
let eventQueue = []
let canStringify = typeof (JSON) !== "undefined" && typeof (JSON.stringify) !== "undefined";

function eventsUrl() {
  return "/tracking_events"
}

function canTrackNow() {
  return canStringify && typeof (window.navigator.sendBeacon) !== "undefined"
}

function serialize(object) {
  let data = new FormData()
  for (let key in object) {
    if (object.hasOwnProperty(key)) {
      data.append(key, object[key])
    }
  }
  return data
}

// cookies
function setCookie(name, value, ttl) {
  Cookies.set(name, value, ttl)
}

function getCookie(name) {
  return Cookies.get(name)
}

function destroyCookie(name) {
  Cookies.set(name, "", -1)
}

function log(message) {
  window.console.log(message)
}

toureyesTracking.ready = function (callback) {
  callback()
}

function documentReady(callback) {
  if (document.readyState === "interactive" || document.readyState === "complete") {
    setTimeout(callback, 0)
  } else {
    document.addEventListener("DOMContentLoaded", callback)
  }
}

// https://stackoverflow.com/a/2117523/1177228
function generateId() {
  return 'xxxxxxxx-xxxx-3xxx-axxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

function saveEventQueue() {
  if (canStringify) {
    setCookie("toureyesTracking_events", JSON.stringify(eventQueue), 1)
  }
}

function sendRequest(url, data, success) {
  return new Promise((resolve, reject) => {
    console.log(url, data)
    serviceApi.post(url, JSON.stringify(data))
      .then(response => {
        resolve(response.data)
      }, err => {
        reject(err)
      })
  })
  // if (canStringify) {
  //   if ($ && $.ajax) {
  //     $.ajax({
  //       type: "POST",
  //       url: url,
  //       data: JSON.stringify(data),
  //       contentType: "application/json charset=utf-8",
  //       dataType: "json",
  //       success: success,
  //       headers: config.headers
  //     })
  //   } else {
  //     let xhr = new XMLHttpRequest()
  //     xhr.open("POST", url, true)
  //     xhr.setRequestHeader("Content-Type", "application/json")
  //     for (let header in config.headers) {
  //       if (config.headers.hasOwnProperty(header)) {
  //         xhr.setRequestHeader(header, config.headers[header])
  //       }
  //     }
  //     xhr.onload = function () {
  //       if (xhr.status === 200) {
  //         success()
  //       }
  //     }
  //     xhr.send(JSON.stringify(data))
  //   }
  // }
}

function eventData(event) {
  let data = {
    events: [event]
  }
  return data
}

function trackEvent(event) {
  toureyesTracking.ready(function () {
    sendRequest(eventsUrl(), eventData(event), function () {
      // remove from queue
      for (let i = 0; i < eventQueue.length; i++) {
        if (eventQueue[i].id == event.id) {
          eventQueue.splice(i, 1)
          break
        }
      }
      saveEventQueue()
    })
  })
}

function trackEventNow(event) {
  toureyesTracking.ready(function () {
    let data = eventData(event)
    // stringify so we keep the type
    data.events_json = JSON.stringify(data.events)
    data.auth = process.env.VUE_APP_SG_AUTHORIZATION
    delete data.events
    window.navigator.sendBeacon(eventsUrl(), serialize(data))
  })
}

toureyesTracking.reset = function () {
  destroyCookie("toureyesTracking_events")
  destroyCookie("toureyesTracking_track")
  return true
}

toureyesTracking.debug = function (enabled) {
  if (enabled === false) {
    destroyCookie("toureyesTracking_debug")
  } else {
    setCookie("toureyesTracking_debug", "t", 365 * 24 * 60) // 1 year
  }
  return true
}

toureyesTracking.track = function (category, action, payload) {
  // generate unique id
  let event = {
    category: category,
    action: action,
    payload: payload || {},
    time: (new Date()).getTime() / 1000.0,
    uuid: generateId(),
    // js: true
  }
  toureyesTracking.ready(function () {

    // if (canTrackNow()) {
    //   trackEventNow(event)
    // } else {
    eventQueue.push(event)
    saveEventQueue()

    // wait in case navigating to reduce duplicate events
    setTimeout(function () {
      trackEvent(event)
    }, 1000)
    // }
  })

  return true
}

// push events from queue
try {
  eventQueue = JSON.parse(getCookie("toureyesTracking_events") || "[]")
} catch (e) {
  // do nothing
}

for (let i = 0; i < eventQueue.length; i++) {
  trackEvent(eventQueue[i])
}

toureyesTracking.start = function () {
  toureyesTracking.start = function () { }
}

documentReady(function () {
  toureyesTracking.start()
})

export default toureyesTracking