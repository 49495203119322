import axios from "axios";
import { Message } from "element-ui";
import store from '@/store/index'

let baseURL = '/api';
// console.log(process.env.NODE_ENV)
switch (process.env.NODE_ENV) {
  case 'development':
    baseURL = 'https://guides.toureyes.cn/api';
    break;
  case 'production':
    baseURL = 'https://guides.toureyes.cn/api';
    break;

  default:
    baseURL = '/api';
    break;
}

// axios.defaults.timeout = 50000;
// axios.defaults.baseURL = baseURL;
// axios.defaults.withCredentials = false;//
const serviceApi = axios.create({
  baseURL: baseURL,
  timeout: 3000
});
const serviceAmap = axios.create({
  baseURL: 'https://restapi.amap.com/v3/weather',
  timeout: 3000
});
const serviceArm = axios.create({
  baseURL: 'http://localhost:9010',
  timeout: 3000
});
//http request 拦截器
serviceApi.interceptors.request.use(
  config => {
    config.headers = {
      'Access-Control-Allow-Origin': '*',
      'SG-Authorization': process.env.VUE_APP_SG_AUTHORIZATION,
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',  //此处是增加的代码，设置请求头的类型
      'SG-Locale': store.state.locale
    };
    return config;
  }, err => {
    return Promise.reject(err);
  }
);


// response 拦截器
axios.interceptors.response.use(
  response => {
    return response.data
  }, err => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = '错误请求'
          break
        case 401:
          err.message = '未授权，请重新登录'
          break
        case 403:
          err.message = '拒绝访问'
          break
        case 404:
          err.message = '请求错误,未找到该资源'
          break
        case 405:
          err.message = '请求方法未允许'
          break
        case 408:
          err.message = '请求超时'
          break
        case 500:
          err.message = '服务器端出错'
          break
        case 501:
          err.message = '网络未实现'
          break
        case 502:
          err.message = '网络错误'
          break
        case 503:
          err.message = '服务不可用'
          break
        case 504:
          err.message = '网络超时'
          break
        case 505:
          err.message = 'http版本不支持该请求'
          break
        default:
          err.message = `连接错误${err.response.status}`
      }
    } else {
      err.message = '连接到服务器失败'
    }
    Message.error(err.message)
    return Promise.resolve(err.response)
  }

)

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    serviceApi.get(url, { params: params }).then(response => {
      if (response && response.data) {
        resolve(response.data.data);
      }
    }).catch(err => {
      reject(err)
    })
  })
}

export function getWeatherInfo(url, params = {}) {
  return new Promise((resolve, reject) => {
    serviceAmap.get(url, {
      params: params
    }).then(response => {
      if (response && response.data) {
        resolve(response.data);
      }
    }).catch(err => {
      reject(err)
    })
  })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    serviceApi.post(url, data)
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    serviceApi.patch(url, data)
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    serviceApi.put(url, data)
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}

export function Delete(url, data = {}) {
  return new Promise((resolve, reject) => {
    serviceApi.delete(url, data)
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}


/**
 * 封装摇臂请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function pointDirection(armNum, lat, lng) {
  return new Promise((resolve, reject) => {
    serviceAmap.get('/MotorDrive/goPosition.do', {
      params: { address: armNum, lon_b: lng, lat_b: lat }
    }).then(response => {
      if (response && response.data) {
        resolve(response.data);
      }
    }).catch(err => {
      reject(err)
    })
  })
}

export function displayLocation(armNum, str) {
  return new Promise((resolve, reject) => {
    serviceAmap.get('/LedDrive/sendDsiplayData.do', {
      params: { address: armNum, mode: 0, color: 2, data: str, length: getByteLength(str) }
    }).then(response => {
      if (response && response.data) {
        resolve(response.data);
      }
    }).catch(err => {
      reject(err)
    })
  })
}

function getByteLength(str) {
  var length = 0;
  var reg = /[\u4e00-\u9fa5]/
  for (var i = 0; i < str.length; i++) {
    if (reg.test(str.charAt(i))) {
      length += 2
    } else {
      length++
    }
  }
  return length
}