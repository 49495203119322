<template>
  <div class="lang-box">
    <div class="lang-select" @click="changeLang">
      <i class="iconfont icon-lang"></i>
      <span class="text">{{ currentLang }}</span>
      <i class="down"></i>
    </div>
    <ul class="lang-options" v-if="showOption">
      <li
        v-for="(item, index) in langs"
        :key="index"
        @click="selectLang(item, index)"
        :class="{ current: itemIndex == index }"
      >
        <a href="javascript:;">{{ item.name }}</a>
        <i class="iconfont icon-gou"></i>
      </li>
    </ul>
  </div>
</template>
  
<script>
import { mapState } from "vuex";


export default {
  name: "Changelang",
  components: {},
  data() {
    return {
      showOption: false,
      langs: [{ name: "中文",label:'zh_CN' }, { name: "ENGLISH",label:'en' },{ name: "日本語",label:'ja' }, { name: "한국어",label:'ko' }],
      currentLang: "中文",
      itemIndex: 0,
    };
  },

  computed: {},
  methods: {
    // 切换语言
    changeLang() {
      this.showOption = !this.showOption;
    },
    // 选择语言
    selectLang(item, index) {
      this.itemIndex = index;
      this.currentLang = item.name;
      this.showOption = false;
      this.$i18n.locale = item.label
      this.$store.commit("SET_LOCALE", item.label)
      this.$ta.track("change_locale", item.label)
    },
  },
  mounted() {},
};
</script>
  
<style lang="less" scoped>
.lang-box {
  width: 100%;
  height: 50px;
  position: relative;
  .lang-select {
    background-color: var(--color);
    color: var(--whiteColor);
    border-radius: 10px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10px;
    cursor: pointer;
    font-weight: 500;
    .icon-lang {
      font-size: 26px;
    }
    .text {
      flex: 1;
      text-align: center;
      font-size: 20px;
    }
    .down {
      width: 0;
      height: 0;
      border-top: 10px solid var(--whiteColor);
      border-left: 8px dashed transparent;
      border-right: 8px dashed transparent;
      display: inline-block;
    }
  }
  .lang-options {
    position: absolute;
    width: 220px;
    background-color: var(--whiteColor);
    border-radius: 10px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0 14px rgb(0 0 0 / 36%);
    top: 140%;
    z-index: 12;
    &::before {
      content: "";
      position: absolute;
      width: 26px;
      height: 24px;
      background: url("@/assets/img/up-bg.png") center bottom no-repeat;
      background-size: 100% auto;
      left: 50%;
      transform: translateX(-50%);
      top: -24px;
    }
    li {
      position: relative;
      .icon-gou {
        display: none;
      }
      a {
        display: block;
        line-height: 58px;
        font-size: 20px;
        text-align: center;
        color: var(--backColor);
      }
      &:not(:last-of-type) {
        border-bottom: 1px solid var(--lineColor);
      }
    }
    li.current {
      .icon-gou {
        position: absolute;
        display: block;
        color: var(--color);
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
.horizontal{
  .lang-box{
    margin-top: 8px;
  }
}
.vertical .lang-box .lang-select {
  border-radius: 30px;
  padding: 0 20px;
  .text {
    width: 110px;
  }
}
</style>
  