<template>
    <div class="header-box header-box-h" v-if="horizontal == 'true'">
        <div class="left">
            <div class="time">{{currentTime}}</div>
            <span class="line"></span>
            <div class="">
                <span class="day">{{currentDay}}</span>
                <changelang></changelang>
            </div>
        </div>
        <div class="title textCenter">
            <span><img class="imgH" src="@/assets/img/logo-icon.png" alt="" /></span>
            <p class="Handwriting">{{name}}</p>
        </div>
        <div class="right">
            <p class="textCenter weather">{{weather}}</p>
            <div class="right-btn" @click="goLink('/scenicspot')">
                <p><span class="iconfont icon-jingqu"></span> {{$t("title")}}</p>
            </div>
        </div>
    </div>
    <div class="header-box header-box-v" v-else>
        <div class="left">
            <div class="textCenter">
                <span class="time displayBlock">{{currentTime}}</span>
                <span class="day">{{currentDay}}</span>
            </div>
            <span class="line"></span>
            <div class="">
                <p class="textCenter weather">{{weather}}</p>
                <changelang></changelang>
            </div>
        </div>
        <div class="right">
            <div class="right-btn" @click="goLink('/scenicspot')">
                <p><span class="iconfont icon-jingqu"></span> {{$t("title")}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import changelang from "./Changelang.vue";

export default {
    name: "Header",
    components: {
        changelang,
    },
    data() {
        return {
            horizontal:null,
        };
    },

    computed: {
        ...mapState(["currentDay", "currentTime","name","weather"]),
    },
    methods: {
        goLink(val) {
            console.log(val);
            this.$router.push({
                path: val,
            });
        },

        
    },
    mounted() {
        this.horizontal = String(localStorage.getItem('screen')) 
    },
    beforeDestroy() {
    }
};
</script>

<style lang="less" scoped>
.header-box {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
        display: flex;
        color: var(--backColor);
        align-items: center;

        .line {
            width: 1px;
            height: 70px;
            background-color: var(--color);
            margin: 0 20px;
        }
    }

    .right {
        .right-btn {
            background-color: var(--color);
            border-radius: 10px;
            opacity: 0.9;
            cursor: pointer;
            padding: 0 10px;

            p {
                opacity: 1;
                color: var(--whiteColor);
            }
        }
    }
}

.header-box-h {
    .left {
        .time {
            font-size: 64px;
        }

        .line {
            margin: 0 10px;
        }

        .day {
            font-size: 30px;
            font-weight: 400;
            line-height: 1;
            color: var(--backColor);
        }
    }

    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 96%;
        font-size: 60px;

        span {
            height: 100%;
            margin-right: 10px;
        }
    }

    .right {
        font-size: 28px;
        margin-left: 6%;

        .weather {
            line-height: 1;
            margin-bottom: 2px;
            color: var(--backColor);

        }

        .right-btn {
            height: 54px;
            line-height: 54px;
            margin-top: 10px;

            .iconfont {
                font-size: 26px;
            }
        }
    }
}

.header-box-v {
    .left {
        .time {
            font-size: 60px;
            line-height: 1.1;
        }

        .line {
            margin: 0 20px;
        }

        .day {
            font-size: 22px;
            font-weight: 400;
            line-height: 1.3;
            color: var(--backColor);

        }
    }

    .weather {
        line-height: 1;
        margin-bottom: 10px;
        font-size: 24px;
        color: var(--backColor);
    }

    .right {
        font-size: 24px;
        margin-left: 6%;

        .right-btn {
            padding: 20px 10px;

            .iconfont {
                font-size: 26px;
                text-align: center;
                margin-bottom: 6px;
            }

            p {
                span {
                    display: block;
                }
            }
        }
    }
}
</style>
