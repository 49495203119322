<template>
  <div class="nav-box">
    <ul class="nav-ul clearfix">
      <li
        v-for="(item, index) in initList"
        :key="index"
        @click="handleNav(index)"
        :class="currentRouter == index ? 'on' : ''"
      >
        <span class="nav-icon-box" v-if="horizontal == 'true'">
          <i class="iconfont" :class="item.icon_class"></i>
        </span>
        <span
          class="nav-icon-box"
          :style="{
            'background-image': `url(${navBg})`,
            'background-size': '100% 100%',
          }"
          v-else
        >
          <i class="iconfont" :class="item.icon_class"></i>
        </span>

        <span>{{ item.name }}</span>
      </li>
    </ul>
  </div>
</template>

  <script>
import { mapState } from "vuex";
import { get } from "@/config/async/axios";

export default {
  name: "Nav",
  components: {},
  data() {
    return {
      horizontal: null, 
      navBg: require(`@/assets/img/${process.env.VUE_APP_IMG_IMG_FOLDER}/nav-bg.png`),
      currentRouter: 0,
      initList: [],
    };
  },
  created() {
    const fullPath = this.$router.history.current.fullPath;
    this.defaultCurrent(fullPath);
    this.handleInitList();
  },

  computed: {
        locale() {
            return this.$store.state.locale
        }
    },
    watch: {
        locale(newVal, oldVal) {
            this.handleInitList()
        }

    },

  methods: {
    async handleInitList() {
      await get("/v1/catalogs", {
        query: { kind_obj_code_eq: "main_navs" },
      }).then((res) => {
        this.initList = res;
      });
    },

    defaultCurrent(currentRouter) {
      
      const detailPage = this.$router.history.current.name;
      const query_news =  this.$router.history.current.query.isNew;
      if(detailPage=="Details"){
        if(query_news==1){
          this.currentRouter = 1;
        }else{
          this.currentRouter = 2;
        }
        return;
      }

      switch (currentRouter) {
        case this.dealRouter(currentRouter, "/guide"):

          this.currentRouter = 0;

          break;
        case this.dealRouter(currentRouter, "/consultation"):
          
          this.currentRouter = 1;

          break;
        case this.dealRouter(currentRouter, "/recommend"):

          this.currentRouter = 2;

          break;
        case this.dealRouter(currentRouter, "/convenience"):

          this.currentRouter = 3;

          break;

        default:
          break;
      }

      



    },
    dealRouter(currentRouter, value) {
      if(currentRouter.indexOf(value)!=-1){
        return value;
      }
    },
    handleNav(mark) {
      this.currentRouter = mark;
      const fullPath = this.$router.history.current.fullPath;
      switch (mark) {
        case 0:
          if (fullPath !== "/guide") {
            //处理再点击当前路由出现报错问题
            this.$router.push({
              path: "/guide",
            });
          }
          break;
        case 1:
          if (fullPath !== "/consultation") {
            this.$router.push({
              path: "/consultation",
            });
          }
          break;
        case 2:
          if (fullPath !== "/recommend") {
            this.$router.push({
              path: "/recommend",
            });
          }
          break;
        case 3:
          if (fullPath !== "/convenience") {
            this.$router.push({
              path: "/convenience",
            });
          }
          break;
        default:
          this.$router.push({
            path: "/guide",
          });
          break;
      }
    },
  },
  mounted() {
    this.horizontal = String(localStorage.getItem('screen'))
  },
};
</script>
  
  <style lang="less" scoped>
@import "@/assets/css/main.css";
.nav-box {
  font-size: 32px;
  li {
    cursor: pointer;
  }
}
.horizontal {
  .nav-box {
    height: 100%;
  }
  .nav-ul {
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: unset;
    flex-direction: column;
    justify-content: center;
    li {
      padding: 24px 0px 24px 30px;
      border-radius: 100px 0px 0px 100px;
      font-size: 33px;
      border: 2px solid var(--color);
      margin-bottom: 60px;
      box-sizing: content-box;
      margin-left: 45px;
      border-right: 0px;
      background-color: var(--whiteColor);

      .nav-icon-box {
        i {
          font-size: 32px;
          margin-right: 20px;
        }
      }
      &.on {
        color: var(--whiteColor);
        background-color: var(--color);
        margin-left: 0px;
        padding: 24px 30px;
      }
      &:hover {
        margin-left: 0px;
        color: var(--whiteColor);
        background-color: var(--color);
        transition: all 0.5s;
      }
    }
  }
}
.vertical {
  .nav-ul {
    li {
      float: left;
      text-align: center;
      width: 25%;
      .nav-icon-box {
        display: block;
        font-size: 32px;
        height: 120px;
        width: 120px;
        color: var(--color);
        margin: 0 auto;
        line-height: 120px;
        opacity: 0.6;

        i {
          font-size: 60px;
        }
      }
      &.on {
        font-size: 32px;
        color: var(--color);

        .nav-icon-box {
          // background-color: var(--color) ;
          opacity: 1;
        }
      }
    }
  }
}
</style>
  