
import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

// 以下为语言包单独设置的场景，单独设置时语言包需单独引入
const messages = {
  'zh_CN': { // 中文语言包
    title: '景点介绍',
    name: '街区资讯',
    back: '返回',
    distance: '距离',
    introduce: '介绍',
    details: '详情',
    navigation: '导航',
    signOut: '退出',
  },
  'en': { // 英文语言包
    title: 'Introduction',
    name: 'News',
    back: 'Go back',
    distance: 'Distance',
    introduce: 'Introduce',
    details: 'details',
    navigation: 'navigation',
    signOut: 'sign out'

  },
  'ja': { // 日语言包
    title: '観光地の紹介',
    name: '街区情報',
    back: 'リターンマッチ',
    distance: 'きょり',
    introduce: '紹介',
    details: '詳細',
    navigation: 'ナビゲーション',
    signOut: '終了'

  },
  'ko': { // 韩语语言包
    title: '관광지 소개',
    name: '지역 정보',
    back: '되돌아오다',
    distance: '거리',
    introduce: '소개',
    details: '상세한 상황',
    navigation: '내비게이션',
    signOut: '물러나다'

  }
}

// 最后 export default，这一步肯定要写的。
export default new VueI18n({
  locale: 'zh_CN', // set locale 默认显示英文
  messages: messages // set locale messages
})