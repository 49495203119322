<template>
    <div id="app" :class="horizontal?'horizontal':'vertical'">
        <router-view></router-view>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import HeaderVue from './components/Header.vue';

export default {
    name: 'App',
    components: {
        HeaderVue
    },
    data() {
        return {
            horizontal: null,
            themeColors:process.env.VUE_APP_COLOR,
            bgColor:process.env.VUE_APP_BG_COLOR,
            borderColor:process.env.VUE_APP_BORDER_COLOR,
            activeColor:process.env.VUE_APP_ACTIVE_COLOR,
        }
    },
    watch: {
        /**
        * 侦听页面可视高度的变化,将底部tabbar display:block
        */
        '$store.state.isHorizontal'() {
            // 使用视口高度变量
            location.reload();
        },
        $route (to){
            this.$ta.track("landing_page", to.path, {meta: to.meta, params: to.params})
        }
    },
    computed: {
    },
    methods: {
        init() {
            let height = document.documentElement.clientHeight
            let width = document.documentElement.clientWidth
            if (width > height) {
                this.$store.commit("changeScreen", 1)
            } else {
                this.$store.commit("changeScreen", 0)
            }

            this.horizontal = this.isHorizontal
        },
        changePrimaryColor() {
            document.documentElement.style.setProperty('--color', this.themeColors);
            document.documentElement.style.setProperty('--bgColor', this.bgColor);
            document.documentElement.style.setProperty('--borderColor', this.borderColor);
            document.documentElement.style.setProperty('--activeColor', this.activeColor);
            },

    },
    mounted() {
        let height = document.documentElement.clientHeight
        let width = document.documentElement.clientWidth
        if (width > height) {
            this.horizontal = true
        } else {
            this.horizontal = false
        }
        localStorage.setItem('screen', this.horizontal)
        window.onresize = () => {
            this.init()
        }

        this.changePrimaryColor()
    },
    destroyed(){
        localStorage.clear()
    }

}
</script>

<style lang="less">
@import "./assets/css/main.css";

#app {
    height: 100%;
    width: 100%;
    background-color: var(--bgColor);
}
</style>
