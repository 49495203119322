<template>
  <div class="routeNav-box">
      <!-- 头部 -->
      <div class="back-btn" @click="goBack()" v-if="horizontal == 'true'">
          <span class="iconfont icon-left"></span>{{$t("back")}}
      </div>
      <div class="til-box">
        <div class="info">
          <span class="arrow-btn" @click="handleTil(1)">
            <i class="iconfont icon-jiantou-left"></i>
          </span>
          <span>{{ currentTilObject.title }}</span>
          <span class="arrow-btn" @click="handleTil(2)">
            <i class="iconfont icon-jiantou-right"></i>
          </span>
        </div>
        <div style="margin: 10px" v-html="currentTilObject.content"></div>
      </div>
      <!-- 横屏 -->
      <div class="routeNav-box-h" v-if="horizontal == 'true'">
        <!-- <el-steps direction="vertical" :active="currentStep">
          <el-step
            :title="item.name"
            v-for="(item, index) in currentTilObject.locations"
            :key="index"
            @click.native="handleStep(item, index)"
          ></el-step>
          <el-step title="步骤 3" description="这是一段很长很长很长的描述性文字"></el-step>
        </el-steps> -->
        <ul
          class="routeNav-ul"
          v-if="currentTilObject && currentTilObject.locations"
        >
          <li
            v-for="(item, index) in currentTilObject.locations"
            :class="currentStep == index + 1 ? 'on' : ''"
            :key="index"
            @click="handleStep(item, index)"
          >
            <span class="step-num">
              {{ index + 1 }}
            </span>
            <span>{{ item.name }}</span>
          </li>
        </ul>
      </div>
      <!-- 竖屏 -->
      <div class="routeNav-box-v" v-else>
        <ul
          class="routeNav-ul"
          v-if="currentTilObject && currentTilObject.locations"
        >
          <li
            v-for="(item, index) in currentTilObject.locations"
            :class="currentStep == index + 1 ? 'on' : ''"
            :key="index"
            @click="handleStep(item, index)"
          >
            <span class="step-num">
              {{ index + 1 }}
            </span>
            <span>{{ item.name }}</span>
          </li>
        </ul>
      </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import { Message } from "element-ui";
import { get } from "@/config/async/axios";
// import KuangTabVue from "@/components/KuangTab.vue";

export default {
  name: "RouteNav",
  components: {
    // KuangTab,
  },
  props: {},
  data() {
    return {
      horizontal: null,
      currentTilObject: [],
      currentTilNum: 0,
      currentStep: 1,
      initTilData: [],
    };
  },

  computed: {},
  created() {
    this.handleInitList();
  },
  methods: {
    async handleInitList() {
      await get("/v1/guidelines").then((res) => {
        // console.log("guidelines",res)
        this.initTilData = res;
        this.currentTilObject = res[0];
        this.$emit("stepItem", res[0].locations[0]);
        // this.currentTilObject = this.initTilData[0];
      });
    },
    handleTil(mark) {
        console.log(mark)
      if (mark == 1) {
        if (this.currentTilNum == 0) {
          return;
        } else {
          this.currentTilObject = this.initTilData[this.currentTilNum - 1];
          this.currentTilNum = this.currentTilNum - 1;
          this.currentStep = 1;
          this.$emit(
            "stepItem",
            this.initTilData[this.currentTilNum - 1].locations[0]
          );
        }
      } else {
        if (this.currentTilNum + 1 == this.initTilData.length) {
          return;
        } else {
          this.currentTilObject = this.initTilData[this.currentTilNum + 1];
          this.currentTilNum = this.currentTilNum + 1;
          this.currentStep = 1;
          this.$emit(
            "stepItem",
            this.initTilData[this.currentTilNum + 1].locations[0]
          );
        }
      }
    },
    handleStep(item, index) {
      this.currentStep = index + 1;
      this.$emit("stepItem", item);
    },

    goBack() {
        this.$emit("close", false);
    },
  },
  mounted() {
    this.horizontal = String(localStorage.getItem('screen'))
  },
};
</script>
<style lang="less" scoped>
.routeNav-box {
  height: 100%;

  .til-box {
    text-align: center;
    font-size: 36px;
    position: relative;
    .info{
        display: flex;
        align-items: center;
        justify-content: center;
    }
  }

  .arrow-btn {
    cursor: pointer;
    margin: 20px;
    vertical-align: middle;
    display: block;

    i {
      font-size: 32px;
      color: var(--color);
    }
  }
}

/deep/.el-step__title.is-finish {
  color: var(--color);
}

/deep/.el-step.is-vertical .el-step__main {
  margin-left: 20px;
}

/deep/.el-step__head.is-finish {
  color: var(--color);
  border-color: var(--color);
}

/deep/.el-step__head.is-finish .el-step__icon.is-text {
  border: 2px solid;
  background-color: var(--color);
}

/deep/.el-step__head.is-finish .el-step__icon-inner {
  font-weight: 700;
  color: #fff;
}

/deep/.el-step__title.is-process,
/deep/.el-step__head.is-process,
/deep/.el-step__icon-inner,
/deep/.el-step__title.is-wait {
  font-weight: normal;
  color: #333;
}

/deep/.el-step__icon.is-text {
  border: 1px solid;
}

/deep/.el-step__icon {
  height: 59px;
  width: 59px;
  left: -19px;
  top: -19px;
}

.routeNav-ul {
    li {
    //   margin-right: 1%;
      margin-bottom: 40px;
      color: var(--color);
    //   padding: 20px 10px;
      cursor: pointer;

      .step-num {
        position: relative;
        z-index: 1;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        font-size: 14px;
        box-sizing: border-box;
        // background: #FFF;
        transition: 0.15s ease-out;
        border-radius: 50%;
        border: 2px solid  var(--borderColor2);
        margin-right: 10px;
        font-size: 28px;
        cursor: pointer;
        vertical-align: middle;
          color: var(--activeColor2);
      }
    }
    li.on {
        color: var(--activeColor2);
        .step-num {
          background-color: var(--borderColor2);
          border-color: var(--activeColor2);
        }
      }
}
.horizontal{
  .routeNav-box-h{
      position: absolute;
      left: 30px;
      right: 30px;
      bottom: 10px;
      top: 256px;
      .routeNav-ul{
          height: 100%;
          overflow: auto;
          position: relative;
          &::before{
              content: '';
              width: 0;
              height: 100%;
              border-left: 1px solid var(--activeColor2);
              position: absolute;
              left: 5px;
              top: 0;

          }
          li{
              position: relative;
              padding-left: 30px;
              &::before{
                  content: '';
                  position: absolute;
                  width: 10px;
                  height: 10px;
                  box-sizing: border-box;
                  border: 1px solid var(--activeColor2);
                  background-color: var(--activeColor2);
                  left: 0;
                  top: 50%;
                  margin-top: -5px;
                  border-radius: 10px;
              }
          }
          li.on{
              &::before{
                  border-color:var(--activeColor2);
                  background-color: var(--borderColor2);
              }
          }
      }
  }
  
}
.vertical{

  .routeNav-box{
    margin-top: 40px;

      // background: #F9FFFC;
        border-top: 1px solid #ccc;
      .back-btn {
        position: absolute;
        left: 0px;
        font-size: 28px;
        top: 10px;
        // margin-top:0;
      }
  }
  .routeNav-box-v {
    /deep/.el-step__line {
      opacity: 0;
    }

    .routeNav-ul {
      
      li {
        float: left;
        width: 32%;
        margin-right: 1%;
        margin-bottom: 40px;

        .step-num {
          position: relative;
          z-index: 1;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 60px;
          height: 60px;
          font-size: 14px;
          box-sizing: border-box;
          // background: #FFF;
          transition: 0.15s ease-out;
          border-color: var(--color);
          border-radius: 50%;
          border: 1px solid;
          margin-right: 10px;
          font-size: 28px;
          cursor: pointer;
        }

        &.on {
          color: var(--color);

          .step-num {
            background-color: var(--color);
            color: #fff;
          }
        }
      }
    }
  }

}

.back-btn {
  margin-top: 6px;
  background-color: var(--color);
  border-radius: 40px;
  cursor: pointer;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  color: var(--whiteColor);
  display: inline-block;
  mix-blend-mode: 20px;
  font-size: 28px;
  font-weight: 500;

  .iconfont {
    font-size: 26px;
    text-align: center;
    vertical-align: middle;
    margin-right: 10px;
  }
}
</style>
