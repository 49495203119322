import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

// 用来存储数据
const state = {
    locale: 'zh_CN',//请求多语言
    isHorizontal: true,
    themeColors: process.env.VUE_APP_COLOR,
    currentDay: "2022-10-10",
    currentTime: "00:00",
    name: '文化历史街区',
    weather: '晴 12°C',
    idleTime: null,
    mapCenter:[120.691659, 30.520884],
}
// 响应组件中的事件
const actions = {

}
// 操作数据
const mutations = {
    changeScreen(state, val) {
        state.isHorizontal = val;
    },
    SET_CURRENT_DAY(state, val) {
        state.currentDay = val;
    },
    SET_CURRENT_TIME(state, val) {
        state.currentTime = val;
    },
    SET_NAME(state, val) {
        state.name = val;
    },
    SET_LOCALE(state, val) {
        state.locale = val;
    },
    SET_WEATHRT(state, val) {
        state.weather = val;
    },
    SET_IDLE_TIME(state, val) {
        state.idleTime = val;
    },
    SET_MAP_CENTER(state, val) {
        state.mapCenter = val;
    }
}
// 用来将state数据进行加工
const getters = {}
// 新建并暴露store
export default new Vuex.Store({
    state,
    actions,
    mutations,
    getters
})
