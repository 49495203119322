<template>
    <div class="datiles-header">
        <div class="datiles-header-top">
            <p class="Handwriting col-1">{{name}}</p>
            <div class="textCenter ">
                <span class="time">{{currentTime}}</span>
                <span class="day">{{currentDay}}</span>
            </div>
            <p class="weather col-1">{{weather}}</p>
        </div>
        <div class="datiles-header-bottom">
            <div class="back-btn" @click="goBack()">
                <span class="iconfont icon-left"></span>
                {{$t("back")}}
            </div>
            <div class="title textCenter" v-if="horizontal">
                <span><img class="imgH" src="@/assets/img/logo-icon.png" alt="" /></span>
                <p class="Handwriting">{{name}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import changelang from "./Changelang.vue";

export default {
    name: "Header",
    components: {
        changelang,
    },
    data() {
        return {
            horizontal: this.$store.state.isHorizontal,
        };
    },

    computed: {
        ...mapState(["currentDay", "currentTime", "name","weather"]),
    },
    methods: {
        goBack() {
            this.$router.back()
        },
    },
    mounted() {
    },
    beforeDestroy() {
    }
};
</script>

<style lang="less" scoped>
.datiles-header {
    height: 100%;
    position: relative;
}

.datiles-header-top {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    left: 0;
    top: -18px;
    font-size: 28px;

    .day {
        font-size: 24px;
        margin-left: 10px;
        color: var(--backColor);
    }

    .col-1 {
        width: 20%;
    }

    .weather {
        text-align: right;
        font-weight: 400;
        color: var(--backColor);
    }
}

.datiles-header-bottom {
    height: 100%;
    padding-top: 12px;
    box-sizing: border-box;
    overflow: hidden;

    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 44px;

        span {
            height: 100%;
            margin-right: 10px;
        }
    }

    .back-btn {
        margin-top: 12px;
        background-color: var(--color);
        border-radius: 40px;
        cursor: pointer;
        padding: 0 20px;
        height: 70%;
        display: flex;
        align-items: center;
        color: var(--whiteColor);
        position: absolute;

        .iconfont {
            font-size: 26px;
            text-align: center;
            vertical-align: middle;
            margin-right: 10px;
        }

    }
}

.vertical {
    .datiles-header-top {
        .textCenter {
            width: 30%;
        }
    }
}
</style>
