<template>
    <div style="height: 100%" @click="updateIdleTime">
        <div class="header">
            <CoolLightBox 
                :items="slideShowItems" 
                :index="slideshowItemIndex"
                :effect="'fade'"
                @close="slideshowItemIndex = null">
            </CoolLightBox>
            <HeaderVue v-if="$route.meta.isShow"></HeaderVue>
            <HeaderDVue v-if="$route.meta.isShowDetails"></HeaderDVue>
        </div>
        <div class="main" :class="{
          homepage: $route.meta.isShow,
          DetailsPage: $route.meta.isShowDetails,
        }" :style="{'background':`url(${bgUrl}) center bottom `,'background-size': '1920px auto'}">
            <div class="content">
                <router-view></router-view>
            </div>
            <div class="nav" v-if="horizontal == 'true'">
                <NavVue></NavVue>
            </div>
            <div :class="$route.meta.showFooter?'nav-middle':'nav-bottom'" v-else>
                <NavVue></NavVue>
            </div>
            <div class="footer">
                <FooterVue v-if="$route.meta.showFooter"></FooterVue>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderVue from "@/components/Header.vue";
import HeaderDVue from "@/components/HeaderD.vue";
import NavVue from "@/components/Nav.vue";
import FooterVue from "@/components/Footer.vue";
import RouteNavVue from "@/components/RouteNav.vue";
import CoolLightBox from "@/components/CoolLightBox.vue";
import { get } from "@/config/async/axios";
import { mapState } from "vuex";

export default {
    name: "Home",
    components: {
        HeaderVue,
        HeaderDVue,
        NavVue,
        FooterVue,
        RouteNavVue,
        CoolLightBox
    },

    data() {
        return {
            horizontal: null,
            time: null,
            screenSaverTime: null,
            bgUrl: require(`@/assets/img/${process.env.VUE_APP_IMG_IMG_FOLDER}/bg-h.png`),
            slideShowItems: [
            //   'https://qingtian-1254300759.cos.ap-shanghai.myqcloud.com/59193886e3c1a.jpeg',
            //   'https://qingtian-1254300759.cos.ap-shanghai.myqcloud.com/59193886e3c1a.jpeg'
            ],
            slideshowItemIndex: null
        };
    },
    watch: {},
    computed: {},
    methods: {
        updateIdleTime() {
            this.$store.commit("SET_IDLE_TIME", (new Date()).getTime())
        },
        currTimeFormat(date) {
            var year = date.getFullYear(); //获取当前时间的年份
            var month = date.getMonth() + 1; //获取当前时间的⽉份，⽉份从0开始，所以需要加⼀
            var day = date.getDate(); //获取当前时间的⽇期，getDay()可以获取星期⼏
            var week = date.getDay(); //获取当前时间的⽇期，getDay()可以获取星期⼏
            var hour = date.getHours(); //获取当前时间的⼩时数
            var minute = date.getMinutes(); //获取当前时间的分钟数
            var second = date.getSeconds(); //获取当前时间的秒数

            month = month < 10 ? "0" + month : month;
            day = day < 10 ? "0" + day : day;
            hour = hour < 10 ? "0" + hour : hour;
            minute = minute < 10 ? "0" + minute : minute;

            return { day: `${year}-${month}-${day}`, time: `${hour}:${minute}` };
        },
        async getRegion() {
            await get("/v1/region").then((res) => {
                // if(res.code == 200){
                    this.$store.commit("SET_NAME", res.name);
                    let center = []
                    center.push(Number(res.lng))
                    center.push(Number(res.lat))
                    this.$store.commit('SET_MAP_CENTER',center)
                // }else{
                //     return
                // }
            });
        },
        async handleWeatherInfo() {
            await get('/v1/weather', { "adcode": `${process.env.VUE_APP_AMAP_ADCODE}` }).then(res => {
                let weatherInfo = `${res.weather} ${res.temperature}°C`
                this.$store.commit("SET_WEATHRT", weatherInfo);
            })

        },
        async loadAds() {
            await get('/v1/ads').then(res => {
                this.slideShowItems = res.data.map((item) => {
                    const props = {
                        title: item.title,
                        src: item.url
                    }
                    if(item.img_url.match(/\.(mp4|avi)/)){
                        props['autoplay'] = true
                    }
                    return props
                })
            })
        }
    },
    computed: {
        ...mapState(["idleTime"])
    },
    mounted() {
        this.horizontal = String(localStorage.getItem('screen'))
        this.time = setInterval(() => {
            const date = new Date()
            let dateStr = this.currTimeFormat(date)
            this.$store.commit("SET_CURRENT_DAY", dateStr.day)
            this.$store.commit("SET_CURRENT_TIME", dateStr.time)

            // screen saver
            if(this.idleTime === null) {
                this.$store.commit("SET_IDLE_TIME", date.getTime())
            }
            if((date.getTime() - this.idleTime) > 300000) {
                this.loadAds()
                if(this.slideshowItemIndex.length > 0){
                    this.slideshowItemIndex = 1
                } else {
                    console.log("no ads")
                    updateIdleTime()
                }
            }
        }, 1000);
        this.screenSaverTime = setInterval(() => {
            // screen saver
            if(this.idleTime === null) {
                this.$store.commit("SET_IDLE_TIME", date.getTime())
            }
            if((date.getTime() - this.idleTime) > 300000) {
                this.loadAds()
                if(this.slideshowItemIndex.length > 0){
                    this.slideshowItemIndex = 1
                } else {
                    console.log("no ads")
                    updateIdleTime()
                }
            }
        }, 5 * 60 * 1000);
        this.getRegion()
    },
    beforeDestroy() {
        clearInterval(this.time);
        clearInterval(this.screenSaverTime);
        this.time = null;
        this.screenSaverTime = null;
    }
};
</script>

<style lang="less">
.image{
    width: 100px;
    height: 100px;
}
.header {
    height: 140px;
    box-sizing: border-box;
    padding: 2% 2%;
}

.main {
    position: absolute;
    top: 140px;
    left: 0;
    right: 0;
    bottom: 0;
}

// 横屏
.horizontal {
    .header {
        background: url("@/assets/img/header-bg.png") center top no-repeat;
        background-size: 100% auto;
        height: 186px;
    }

    .main {
        display: flex;
        top: 186px;

        .content {
            flex: 1;
            height: 100%;
        }

        .nav {
            width: 276px;
        }
    }
}

// 竖屏
.vertical {

    // 主页
    .main.homepage {
        padding: 0 15px;

        .content {
            height: 70%;
        }

        .nav-middle {
            position: relative;
            z-index: 2;
        }

        .footer {
            position: fixed;
            height: 20%;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    // 详情页
    .main.DetailsPage {
        .content {
            height: 40%;
        }

        .nav-bottom {

            position: fixed;
            bottom: 40px;
            width: 100%;
        }
    }
}
</style>
