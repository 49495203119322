import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index'
import store from '@/store/index'
import ElementUI from "element-ui"
import axios from 'axios'
import i18n from './utils/i18n'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/text.css'

import tracking from '@/lib/Tracking'
Vue.prototype.$ta = tracking

Vue.use(ElementUI);
Vue.prototype.$http = axios;
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
  i18n
}).$mount('#app')
